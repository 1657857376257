import React from 'react';
// import {Form2FileUpload} from "react-spaceship-web";

export default class SaleOrderViaECommerce extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			qs: window.Global.getAllQueryStrings(),
		};

		window.SaleOrderViaECommerce = this;
	}

	componentDidMount() {
		if (this.props.mode === 'list') {
			this.fetch();
		}
	}

	fetch() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/sale_order_via_e_commerce',
			data: {
				status: 'all',
			},
			done: (response) => {
				if (!response.success) {
					return false;
				}
				this.setState({
					is_loading: false,
					sale_order_via_e_commerce: response.sale_order_via_e_commerce,
				}, () => {
					this.$parent.find('.table').tablePagination({
						data: response.sale_order_via_e_commerce,
						count: response.count
					});
				});
			}
		});
	}

	renderList() {
		if (this.state.is_loading) {
			return null;
		}

		return (
			<div className="secondBackground fluid">
				<div style={{
					background: '#ffffffe0',
					padding: '2rem',
				}}>
					<div className="ui container">
						<h1 className="ui header"
						    style={{margin: 0, color: '#000', letterSpacing: '1px'}}>
							<i className="icon cart" style={{fontSize: '2rem', lineHeight: 1}} />ประวัติการสั่งซื้อ
						</h1>
					</div>
				</div>
				<div ref={(ref) => {
					this.$parent = window.$(ref);
				}}
				     className="ui container form" style={{marginTop: '3rem'}}>
					<div className="table">
						{
							this.state.sale_order_via_e_commerce.map((sale_order_via_e_commerce, i_sale_order_via_e_commerce) => {
								if (sale_order_via_e_commerce.sale_order.sale_order_default_shipment.payment_by_id !== 11) {
									return null;
								}
								return (
									<div className="tr"
									     key={i_sale_order_via_e_commerce}>
										{
											sale_order_via_e_commerce.sale_order.paid_status !== 'full'
												? (
													<div className="ui form col-xs-16 orderList">
														<div className="header flex row wrap middle-xs">
															<div><b>{sale_order_via_e_commerce.sale_order.desc}</b></div>
															<button type="button"
															        style={{marginLeft: '1rem'}}
															        className="ui button icon mini green"
															        onClick={() => {
																        window.Global.confirm({
																	        title: 'ยืนยันออเดอร์',
																	        content: '<form class="ui form">\n\t<p>ยืนยันออเดอร์</p>\n\t<div class="ui error message"></div>\n</form>',
																	        cancel_text: 'ยกเลิก',
																	        approve_text: 'ยืนยันออเดอร์',
																	        onShowToSetupForm: ($modal) => {
																		        $modal.find('form').form2('setup');
																		        $modal.find('form').form2('submit', {
																			        method: 'POST',
																			        url: '/api/sale_order/payment',
																			        loadingButton: $modal.find('.button'),
																			        beforeSent: (data) => {
																				        data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																				        data.sale_order_id = sale_order_via_e_commerce.sale_order.id;
																				        return data;
																			        },
																			        onSuccess: (response) => {
																				        $modal.modal('hide');
																				        window.Global.messageTop({
																					        type: 'positive',
																					        message: 'บันทึกข้อมูลเรียบร้อย'
																				        });

																				        let sale_order_via_e_commerce = [...this.state.sale_order_via_e_commerce];
																				        sale_order_via_e_commerce[i_sale_order_via_e_commerce] = response.sale_order_via_e_commerce;
																				        this.setState({
																					        sale_order_via_e_commerce: sale_order_via_e_commerce,
																				        });
																			        }
																		        });
																	        }
																        });
															        }}>
																ยืนยันออเดอร์
															</button>
															<button type="button"
															        style={{marginLeft: '1rem'}}
															        className="ui button icon mini red"
															        onClick={() => {
																        window.Global.confirm({
																	        title: 'ลบออเดอร์',
																	        content: '<form class="ui form">\n\t<p>ยืนยันลบออเดอร์</p>\n\t<div class="ui error message"></div>\n</form>',
																	        cancel_text: 'ยกเลิก',
																	        approve_text: 'ลบออเดอร์',
																	        onShowToSetupForm: ($modal) => {
																		        $modal.find('form').form2('setup');
																		        $modal.find('form').form2('submit', {
																			        method: 'DELETE',
																			        url: '/api/sale_order',
																			        loadingButton: $modal.find('.button'),
																			        beforeSent: (data) => {
																				        data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																				        data.sale_order_id = sale_order_via_e_commerce.sale_order.id;
																				        return data;
																			        },
																			        onSuccess: (response) => {
																				        $modal.modal('hide');
																				        window.Global.messageTop({
																					        type: 'positive',
																					        message: 'ลบออเดอร์เรียบร้อย'
																				        });
																				        window.location.reload();
																			        }
																		        });
																	        }
																        });
															        }}>
																ลบออเดอร์
															</button>
														</div>
														<div className="content">
															{
																sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, i) => {
																	return (
																		<div className="flex row wrap padded"
																		     style={{marginBottom: '1rem'}}
																		     key={i}>
																			<div className="col-xs-16 padded"
																			     style={{
																				     fontSize: '1.1rem',
																				     fontWeight: 'bold',
																			     }}>
																				<div className="flex row wrap OrderItemList">
																					<div>
																						<p>
																							<b>{sale_order_item.sale_order_item_product_display_link.product_display_link.product_display.name}</b>
																						</p>
																						<p>{sale_order_item.quantity.format(2)} รายการ</p>
																					</div>
																					<div className="right floated">
																						<p>{(sale_order_item.summary.total_net_price).format(2)} บาท</p>
																					</div>
																				</div>
																			</div>
																			<div className="col-xs-16 padded flex row wrap">
																				<div
																					className="col-lg-8 col-xs-16 padded center-xs">
																					<div className="field column"
																					     style={{width: '100%'}}>
																						<div>
																							{
																								sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].payment_by_bank_transfer_draft_file_upload
																									?
																									sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].payment_by_bank_transfer_draft_file_upload.map((payment_by_bank_transfer_draft_file_upload, i2) => {
																										return (
																											<div key={i2}
																											     data-disabled-link=""
																											     onClick={() => {
																												     // window.Gallery.show(
																												     // 	[
																												     // 		{
																												     // 			width: payment_by_bank_transfer_draft_file_upload.file_upload.width,
																												     // 			height: payment_by_bank_transfer_draft_file_upload.file_upload.height,
																												     // 			file_path: payment_by_bank_transfer_draft_file_upload.file_upload.url
																												     // 		}
																												     // 	],
																												     // 	0
																												     // )
																											     }}>
																												<img
																													className=""
																													style={{
																														maxWidth: '100%',
																													}}
																													src={payment_by_bank_transfer_draft_file_upload.file_upload.url}
																													alt="หลักฐานการชำระเงิน"
																												/>
																											</div>
																										)
																									})
																									: null
																							}
																						</div>
																					</div>
																				</div>
																				<div className="col-lg-8 col-xs-16 padded">
																					<div
																						className="flex row wrap three column computer">
																						<div className="field column">
																							<label
																								htmlFor="">วันที่โอนเงิน</label>
																							<p>{window.Global.getDateThai(sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].date_transfer)}</p>
																						</div>
																						<div className="field column">
																							<label htmlFor="">จำนวนเงิน</label>
																							<p>฿{sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].total_net_price.format(2)}</p>
																						</div>
																						<div className="field column">
																							<label
																								htmlFor="">วันที่แจ้งโอน</label>
																							<p>{window.Global.getDateThai(sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].date_created)}</p>
																						</div>
																						<div className="field column"
																						     style={{width: '100%'}}>
																							{
																								sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].status === 'wait-processing'
																									?
																									<button
																										className="ui button gray small fluid"
																										type="button">รอตรวจสอบแจ้งการโอนเงิน
																									</button>
																									: null
																							}
																							{
																								sale_order_via_e_commerce.payment_by_bank_transfer_draft[0]['payment_by_bank_transfer_related_draft'].map((payment_by_bank_transfer_related_draft, i_payment_by_bank_transfer_related_draft) => {
																									return (
																										<button
																											key={i_payment_by_bank_transfer_related_draft}
																											className="ui button green small fluid"
																											type="button">โอนเงินถูกต้อง
																											฿{payment_by_bank_transfer_related_draft.payment_by_bank_transfer.value.format(2)}
																										</button>
																									)
																								})
																							}
																							{
																								sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].status === 'rejected'
																									?
																									<button
																										className="ui button red small fluid"
																										type="button">แจ้งโอนเงินไม่ถูกต้อง
																									</button>
																									: null
																							}
																						</div>
																						<div className="field column"
																						     style={{
																							     width: '100%',
																							     opacity: sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].status === 'wait-processing'
																								     ? 1 : 0.2,
																						     }}>
																							{/*<label htmlFor="">จัดการ</label>*/}
																							<button
																								className="ui button small red"
																								type="button"
																								onClick={() => {
																									window.Global.confirm({
																										title: 'ยืนยันโอนเงินไม่ถูกต้อง',
																										content: '<form class="ui form">\n\t<p>ยืนยันโอนเงินไม่ถูกต้องหรือไม่</p>\n\t<input type="hidden" name="status" value="rejected">\n\t<div class="ui error message"></div>\n</form>',
																										cancel_text: 'ไม่ดำเนินการ',
																										approve_text: 'โอนเงินไม่ถูกต้อง',
																										onShowToSetupForm: ($modal) => {
																											$modal.find('form').form2('setup');
																											$modal.find('form').form2('submit', {
																												method: 'POST',
																												url: '/api/payment_by_bank_transfer/approve',
																												loadingButton: $modal.find('.button'),
																												beforeSent: (data) => {
																													data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																													data.payment_by_bank_transfer_draft_id = sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].id;
																													return data;
																												},
																												onSuccess: (response) => {
																													$modal.modal('hide');
																													window.Global.messageTop({
																														type: 'positive',
																														message: 'บันทึกข้อมูลเรียบร้อย'
																													});

																													let sale_order_via_e_commerce = [...this.state.sale_order_via_e_commerce];
																													sale_order_via_e_commerce[i_sale_order_via_e_commerce] = response.sale_order_via_e_commerce;
																													this.setState({
																														sale_order_via_e_commerce: sale_order_via_e_commerce,
																													});
																												}
																											});
																										}
																									});
																								}}>ไม่ถูกต้อง
																							</button>
																							<button
																								className="ui button small green"
																								type="button"
																								onClick={() => {
																									window.Global.confirm({
																										title: 'ยืนยันโอนเงินถูกต้อง',
																										content: '<form class="ui form">\n\t<p>ยืนยันโอนเงินถูกต้องหรือไม่</p>\n\t<input type="hidden" name="status" value="approved">\n\t<div class="ui error message"></div>\n</form>',
																										cancel_text: 'ไม่ดำเนินการ',
																										approve_text: 'โอนเงินถูกต้อง',
																										onShowToSetupForm: ($modal) => {
																											$modal.find('form').form2('setup');
																											$modal.find('form').form2('submit', {
																												method: 'POST',
																												url: '/api/payment_by_bank_transfer/approve',
																												loadingButton: $modal.find('.button'),
																												beforeSent: (data) => {
																													data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																													data.payment_by_bank_transfer_draft_id = sale_order_via_e_commerce.payment_by_bank_transfer_draft[0].id;
																													return data;
																												},
																												onSuccess: (response) => {
																													$modal.modal('hide');
																													window.Global.messageTop({
																														type: 'positive',
																														message: 'บันทึกข้อมูลเรียบร้อย'
																													});

																													let sale_order_via_e_commerce = [...this.state.sale_order_via_e_commerce];
																													sale_order_via_e_commerce[i_sale_order_via_e_commerce] = response.sale_order_via_e_commerce;
																													this.setState({
																														sale_order_via_e_commerce: sale_order_via_e_commerce,
																													});
																												}
																											});
																										}
																									});
																								}}>ถูกต้อง
																							</button>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																})
															}
														</div>
													</div>
												) : null
										}
										{
											sale_order_via_e_commerce.sale_order['paid_status'] === 'full'
											&& sale_order_via_e_commerce.sale_order.payment.map((payment, i) => {
												if (payment['is_deleted']) {
													return null;
												}
												return (
													<div className="ui form col-xs-16 orderList"
													     key={i}>
														<div className="col-xs-16">
															<div className="header flex row wrap middle-xs">
																<div>
																	<b>{payment.desc}</b>
																</div>
																<div style={{margin: '0 1rem'}} className="ui label green">
																	<i className="icon check"></i>ชำระเงิน
																</div>
																<button type="button"
																        onClick={() => {
																	        window.Global.confirm({
																		        title: 'ยกเลิก',
																		        content: '<form class="ui form">\n\t<p>ยืนยันยกเลิก</p>\n\t<div class="ui error message"></div>\n</form>',
																		        cancel_text: 'ไม่ดำเนินการ',
																		        approve_text: 'ยืนยันยกเลิก',
																		        onShowToSetupForm: ($modal) => {
																			        $modal.find('form').form2('setup');
																			        $modal.find('form').form2('submit', {
																				        method: 'DELETE',
																				        url: '/api/sale_order/payment',
																				        loadingButton: $modal.find('.button'),
																				        beforeSent: (data) => {
																					        data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																					        data.sale_order_id = sale_order_via_e_commerce.sale_order.id;
																					        return data;
																				        },
																				        onSuccess: (response) => {
																					        $modal.modal('hide');
																					        window.Global.messageTop({
																						        type: 'positive',
																						        message: 'บันทึกข้อมูลเรียบร้อย'
																					        });

																					        let sale_order_via_e_commerce = [...this.state.sale_order_via_e_commerce];
																					        sale_order_via_e_commerce[i_sale_order_via_e_commerce] = response.sale_order_via_e_commerce;
																					        this.setState({
																						        sale_order_via_e_commerce: sale_order_via_e_commerce,
																					        });
																				        }
																			        });
																		        }
																	        });
																        }}
																        className="ui button icon mini red">
																	<i className="icon trash"/>
																</button>
															</div>
															<div className="content">
																{
																	payment['payment_item'].map((payment_item, i) => {
																		return (
																			<div className="flex row wrap padded"
																			     style={{marginBottom: '1rem'}}
																			     key={i}>
																				<div
																					className="col-lg-8 col-xs-16 padded"
																					style={{
																						fontSize: '1.1rem',
																						fontWeight: 'bold',
																					}}>
																					<div
																						className="flex row wrap OrderItemList">
																						<div>
																							<p>
																								<span className="ui basic label">EA</span> <b>{payment_item.product.name}</b>
																							</p>
																							<p>{payment_item.quantity.format(2)} รายการ</p>
																						</div>
																						<div className="right floated">
																							<p>{(payment_item.summary.total_net_price).format(2)} บาท</p>
																						</div>
																					</div>
																				</div>
																				<div
																					className="col-lg-8 col-xs-16 padded">
																					{
																						payment_item.link_payment_item
																						&& payment_item.link_payment_item.link_payment_item_license
																							?
																							payment_item.link_payment_item.link_payment_item_license.map((link_payment_item_license, i) => {
																								return (
																									<div key={i}
																									     className="ui box field">
																										<label
																											htmlFor="">ไลเซนส์สำหรับบัญชี</label>
																										{
																											link_payment_item_license.link_payment_item_license_active_history.map((link_payment_item_license_active_history, i) => {
																												let $e;
																												return (
																													<div
																														key={i}
																														ref={(ref) => {
																															$e = window.$(ref);
																														}}
																														style={{
																															marginTop: '0.8rem'
																														}}
																														className="ui action input">
																														<input
																															type="text"
																															placeholder="ระบุเลขบัญชี"
																															style={{
																																textAlign: 'center',
																																fontWeight: 'bold',
																															}}
																															name="account_no"
																															defaultValue={link_payment_item_license_active_history.account_no}
																															readOnly={!!link_payment_item_license_active_history.account_no}
																														/>
																														{
																															!link_payment_item_license_active_history.account_no
																																?
																																<button
																																	type="button"
																																	className="ui button green"
																																	onClick={() => {
																																		window.Global.confirm({
																																			title: 'ยืนยันเลขที่บัญชี',
																																			content: window.Handlebars.compile('<form class="ui form">\n\t<p>โปรดยืนยันเลขที่บัญชี <b>{{account_no}}</b> การกระทำนี้ไม่สามารถแก้ไขได้</p>\n\t<input type="hidden" name="account_no" value="{{account_no}}"/>\n\t<div class="ui error message"></div>\n</form>')(
																																				{
																																					account_no: $e.find('input[name="account_no"]').val()
																																				}
																																			),
																																			cancel_text: 'ยกเลิก',
																																			approve_text: 'ยืนยัน',
																																			onShowToSetupForm: ($modal) => {
																																				$modal.find('form').form2('setup');
																																				$modal.find('form').form2('submit', {
																																					method: 'PUT',
																																					url: '/api/link_payment_item_license_active_history',
																																					loadingButton: $modal.find('.button'),
																																					beforeSent: (data) => {
																																						data.link_payment_item_license_active_history_id = link_payment_item_license_active_history.id;
																																						data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																																						data.sale_order_id = sale_order_via_e_commerce.sale_order.id;
																																						return data;
																																					},
																																					onSuccess: (response) => {
																																						$modal.modal('hide');
																																						window.Global.messageTop({
																																							type: 'positive',
																																							message: 'บันทึกข้อมูลเรียบร้อย',
																																						});

																																						let sale_order_via_e_commerce = [...this.state.sale_order_via_e_commerce];
																																						sale_order_via_e_commerce[i_sale_order_via_e_commerce] = response.sale_order_via_e_commerce;
																																						this.setState({
																																							sale_order_via_e_commerce: sale_order_via_e_commerce,
																																						});
																																					}
																																				});
																																			}
																																		});
																																	}}>บันทึก
																																</button>
																																: null
																														}
																														{
																															link_payment_item_license_active_history.account_no
																																?
																																<button
																																	type="button"
																																	className="ui button icon green disabled">
																																	<i className="icon check"/>
																																</button>
																																: null
																														}
																													</div>
																												)
																											})
																										}
																										<button
																											type="button"
																											style={{
																												marginTop: '0.8rem'
																											}}
																											className="ui button green fluid small"
																											onClick={() => {
																												let link_payment_item_license_active_history = false;
																												window.$.each(link_payment_item_license.link_payment_item_license_active_history, (i, _link_payment_item_license_active_history) => {
																													link_payment_item_license_active_history = _link_payment_item_license_active_history;
																												});
																												window.Global.confirm({
																													title: 'เปลี่ยนบัญชี',
																													content: window.Handlebars.compile('<form class="ui form">\n\t<div class="field">\n\t\t<label for="">เลขบัญชีเดิม</label>\n\t\t<input type="text" name="old_account_no" readonly="readonly" value="{{old_account_no}}">\n\t</div>\n\t<div class="field">\n\t\t<label for="">เลขบัญชีใหม่</label>\n\t\t<input type="text" name="new_account_no">\n\t</div>\n\t<p style="color: red;">โปรดตรวจสอบอย่างละเอียด การกระทำนี้ไม่สามารถแก้ไขได้</p>\n\t<div class="ui error message"></div>\n</form>')({
																														old_account_no: link_payment_item_license_active_history.account_no
																													}),
																													cancel_text: 'ยกเลิก',
																													approve_text: 'ยืนยันเปลี่ยนบัญชี',
																													onShowToSetupForm: ($modal) => {
																														$modal.find('form').form2('setup');
																														$modal.find('form').form2('submit', {
																															method: 'POST',
																															url: '/api/change_account_no',
																															loadingButton: $modal.find('.button'),
																															beforeSent: (data) => {
																																data.sale_order_via_e_commerce_id = sale_order_via_e_commerce.id;
																																data.sale_order_id = sale_order_via_e_commerce.sale_order.id;
																																data.link_payment_item_license_active_history_id = link_payment_item_license_active_history.id;
																																return data;
																															},
																															onSuccess: (response) => {
																																$modal.modal('hide');
																																window.Global.messageTop({
																																	type: 'positive',
																																	message: 'บันทึกข้อมูลเรียบร้อย'
																																});

																																let sale_order_via_e_commerce = [...this.state.sale_order_via_e_commerce];
																																sale_order_via_e_commerce[i_sale_order_via_e_commerce] = response.sale_order_via_e_commerce;
																																this.setState({
																																	sale_order_via_e_commerce: sale_order_via_e_commerce,
																																});
																															}
																														});
																													}
																												});
																											}}>เปลี่ยนบัญชี
																										</button>
																									</div>
																								)
																							})
																							: null
																					}
																				</div>
																			</div>
																		)
																	})
																}
															</div>
														</div>
													</div>
												)
											})
										}
									</div>
								)
							})
						}
						<div className="tbody"/>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.mode === 'list') {
			return this.renderList();
		}

		return null;
	}
}
