import React from 'react';
import DisplayPrice from "./DisplayPrice";
import {Form2FileUpload} from "react-spaceship-web";

export default class Cart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			qs: window.Global.getAllQueryStrings(),
			hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
			minute: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
				'11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
				'21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
				'31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
				'41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
				'51', '52', '53', '54', '55', '56', '57', '58', '59'],
		};

		window.Cart = this;
	}

	componentDidMount() {
		if (this.props.mode === 'list') {
			this.fetch();
		}
	}

	fetch() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/sale_order/cart',
			data: {
				// isForECommerce: 1,
			},
			done: (response) => {
				if (!response.success) {
					return false;
				}
				this.update(response);
			}
		});
	}

	update(data) {
		this.setState({
			is_loading: false,
			sale_order_via_e_commerce: data.sale_order_via_e_commerce
				? data.sale_order_via_e_commerce
				: this.state.sale_order_via_e_commerce,
			payment_by: data.payment_by
				? data.payment_by
				: this.state.payment_by,
			relationship_account: data.relationship_account
				? data.relationship_account
				: this.state.relationship_account,
		}, () => {
			if (!this.state.sale_order_via_e_commerce) {
				return;
			}
			let selected = [];
			this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, i) => {
				selected.push(sale_order_item.sale_order_item_product_display_link.product_display_link_id);
				return true;
			});
			window.$('.addProductToCartButton').each((e, node) => {
				let product_display_link_id = window.$(node).data('product_display_link_id');
				if (selected.indexOf(product_display_link_id) !== -1) {
					window.ProductDisplay.makeButtonAdded(
						product_display_link_id
					);
				} else {
					window.ProductDisplay.makeButtonNoAdded(
						product_display_link_id
					);
				}
			});
		});
	}

	changeQty(product_display_link_id, _qty) {
		if (window.delayUpdateCart) {
			clearTimeout(window.delayUpdateCart);
		}
		window.delayUpdateCart = setTimeout(() => {
			this.addProductToCart(
				product_display_link_id,
				_qty
			);
			clearTimeout(window.delayUpdateCart);
			window.delayUpdateCart = false;
		}, 700);
	}

	addProductToCart(product_display_link_id, _quantity) {
		if (this.isUpdatingCart) {
			return false;
		}
		if (!window.Global2.getMyProfile().id) {
			window.Global.messageTop({
				type: 'negative',
				message: 'กรุณาเข้าสู่ระบบก่อนทำการสั่งซื้อ'
			});
			// window.$(this.add_cart).removeClass('loading');
			// window.$(this.buy_btn).removeClass('loading');
			// window.$(this.add_cart).attr('disabled', false);
			// window.$(this.buy_btn).attr('disabled', false);
			return;
		}

		this.isUpdatingCart = true;
		window.$('.manageItem').css({
			opacity: 0.3
		});

		let data = {
			sale_order_item: {
				sale_order_item_product_display_link: {
					product_display_link_id: product_display_link_id,
					_quantity: _quantity,
				}
			}
		};

		window.Global.ajax({
			method: 'POST',
			url: '/api/v1/sale_order/manage_item_cart',
			data: data,
			done: (response) => {
				this.isUpdatingCart = false;
				window.$('.manageItem').css({
					opacity: 1
				});
				// console.log(response);
				if (response.success) {
					window.Global.messageTop({
						type: 'positive',
						message: 'อัพเดทสินค้าในตะกร้าเรียบร้อย'
					});
					window.Cart.update(response);
				}
				if (response.error) {
					window.Global.messageTop({
						type: 'negative',
						message: response.message[0].error_message
					});
				}
				// window.$(this.add_cart).removeClass('loading');
				// window.$(this.buy_btn).removeClass('loading');
				// window.$(this.add_cart).attr('disabled', false);
				// window.$(this.buy_btn).attr('disabled', false);
			}
		});
	}

	renderList() {
		if (this.state.is_loading) {
			return null;
		}

		if (window.location.pathname === '/checkout') {
			if (!this.state.sale_order_via_e_commerce) {
				return (
					<div className="ui container">
						<div className="flex row center-xs">
							<div style={{padding: '10rem 0'}}>
								<p><b>ไม่มีสินค้าในตะกร้า</b></p>
								<a className="ui button blue large" href="/">กลับสู่หน้าหลัก</a>
							</div>
						</div>
					</div>
				);
			}

			return (
				<div className="secondBackground fluid">
					<div className="ui container" style={{marginTop: '2rem'}}>
						<div className="ui box" style={{marginBottom: '2rem'}}>
							<h1 className="ui header dividing" style={{marginBottom: '1.6rem'}}>สรุปรายการสั่งซื้อ</h1>
							<div className="flex row wrap padded">
								<div className="col-lg-10 col-xs-16 padded">
									<table className="ui very table">
										<thead>
										<tr>
											<th>รายการ</th>
											<th>ราคา</th>
											<th>จำนวน</th>
											<th>ราคารวม</th>
											<th/>
										</tr>
										</thead>
										<tbody>
										{
											this.state.sale_order_via_e_commerce
												?
												this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, i) => {
													let qty = parseFloat(sale_order_item.sale_order_item_product_display_link._quantity);
													if (qty < 0 || isNaN(qty)) {
														qty = 0;
													}
													let $input;
													return (
														<tr key={i}
														    ref={(ref) => {
															    $input = window.$(ref).find('input[name="quantity"]');
														    }}
														    style={{
															    position: 'relative',
														    }}>
															<td>
																<b>{sale_order_item.sale_order_item_product_display_link.product_display_link.product_display.name}</b>
															</td>
															<td>
																<b>{(sale_order_item.summary.total_net_price / qty).format(2)}</b>
															</td>
															<td>
																<div className="field controlCartItem" style={{margin: 0}}>
																	<div className="ui left action input">
																		<button className="ui icon button green manageItem"
																		        onClick={() => {
																			        qty = qty - 1;
																			        if (qty <= 0) {
																				        qty = 0;
																			        }
																			        $input.val(qty);
																			        this.changeQty(
																				        sale_order_item.sale_order_item_product_display_link.product_display_link_id,
																				        qty
																			        )
																		        }}>
																			<i className="minus icon"/>
																		</button>
																		<input type="text"
																		       className="transparent manageItem"
																		       name="quantity"
																		       placeholder="Quantity"
																		       value={qty}
																		       readOnly={true}
																		       autoComplete="off"
																		       style={{
																			       width: '6rem',
																			       fontWeight: 'bold',
																			       fontSize: '1.1rem',
																			       textAlign: 'center',
																		       }}
																		       onChange={(event) => {
																			       // console.log(event.target.value);
																			       let qty = parseFloat(event.target.value);
																			       if (isNaN(qty)) {
																				       qty = 0;
																			       }
																			       $input.val(qty);
																			       this.changeQty(
																				       sale_order_item.sale_order_item_product_display_link.product_display_link_id,
																				       qty
																			       )
																		       }}
																		       onFocus={(e) => {
																			       $input.select();
																		       }}
																		/>
																		<button className="ui icon button green manageItem"
																		        style={{
																			        borderRadius: '0 .28571429rem .28571429rem 0',
																		        }}
																		        onClick={() => {
																			        qty = qty + 1;
																			        $input.val(qty);
																			        this.changeQty(
																				        sale_order_item.sale_order_item_product_display_link.product_display_link_id,
																				        qty
																			        )
																		        }}>
																			<i className="plus icon"/>
																		</button>
																	</div>
																</div>
															</td>
															<td>
																<b>{sale_order_item.summary.total_net_price.format(2)}</b>
															</td>
														</tr>
													)
												})
												: null
										}
										</tbody>
									</table>
									<div>
										*คำเตือน การลงทุนมีความเสี่ยง ผลงานในอดีตไม่ได้การันตีอนาคต
										<br/>
										*การลงทุนมีความเสี่ยง ผู้ลงทุนควรทำความเข้าใจลักษณะสินค้า เงื่อนไขผลตอบแทนและความเสี่ยงก่อนตัดสินใจลงทุน ผลการดำเนินงานในอดีต มิได้เป็นสิ่งยืนยันถึงผลการดำเนินงานในอนาคต
									</div>
								</div>
								<div className="col-lg-6 col-xs-16 padded">
									<form className="ui segment form"
									      ref={(ref) => {
										      window.$(ref).find('.ui.radio.checkbox').checkbox();
										      window.$(ref).form2('setup');
										      window.$(ref).form2('submit', {
											      method: 'PUT',
											      url: '/api/v1/e-commerce/sale_order_via_e_commerce',
											      beforeSent: (data) => {
												      data.sale_order_default_shipment = {
													      is_issued_in_another_name: 0,
													      is_company: this.state.relationship_account.relationship.is_company,
													      remark: '',
													      shipment_method_id: 1000,
													      payment_by_id: 11,
												      };
												      data.payment_by_bank_transfer_draft = {
													      bank_id: data.bank_id,
													      total_net_price: data.total_net_price,
													      date_transfer: data.date_transfer,
													      time_transfer: data.hour + ':' + data.minute,
													      payment_by_bank_transfer_draft_file_upload: [
														      {
															      filename: data.payment_by_bank_transfer_draft_file_upload,
														      }
													      ],
												      };
												      data.sale_order = {
													      phone: this.state.relationship_account.phone_number,
													      email: this.state.relationship_account.email,
													      buyer_company_name: this.state.relationship_account.relationship.name,
													      buyer_branch_name: this.state.relationship_account.relationship.is_company
														      ? this.state.relationship_account.relationship.relationship_branch[0].name
														      : '',
													      buyer_branch_code: this.state.relationship_account.relationship.is_company
														      ? this.state.relationship_account.relationship.relationship_branch[0].code
														      : '',
													      buyer_bill_line_1: this.state.relationship_account.relationship.is_company
														      ? this.state.relationship_account.relationship.relationship_branch[0].bill_line_1
														      : this.state.relationship_account.relationship.print_address,
													      buyer_bill_line_2: this.state.relationship_account.relationship.is_company
														      ? this.state.relationship_account.relationship.relationship_branch[0].bill_line_2
														      : '',
													      buyer_tax_number: this.state.relationship_account.relationship.tax_number,
												      };
												      data.is_committed = 1;
												      return data;
											      },
											      loadingButton: window.$(ref).find('.button'),
											      onSuccess: () => {
												      window.Global.messageTop({
													      type: 'positive',
													      message: 'ขอบคุณ แจ้งการโอนเงินเรียบร้อยแล้ว โปรดรอการตรวจสอบจากเจ้าหน้าที่'
												      });
												      window.location = '/history';
											      }
										      });
									      }}>
										<h2 className="ui header dividing flex row wrap between-xs">
											<span>ยอดชำระเงิน</span><span style={{color: 'green'}}>{this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price.format(2)} บาท</span>
										</h2>
										<h3 className="ui header dividing">เลือกธนาคาร</h3>
										{
											this.state.payment_by.map((payment_by) => {
												if (payment_by.id !== 11) {
													return null;
												}
												return payment_by.banks.map((bank, i) => {
													return (
														<div className={"flex row wrap middle-xs selectBank"
														+ (i === 0 ? " selected " : "")}
														     key={i}
														     ref={(ref) => {
															     window.$(ref).on('click', () => {
																     window.$('.selectBank').removeClass('selected');
																     window.$(ref).find('input[value="' + bank.id + '"]')
																	     .prop('checked', true)
																	     .parents('.selectBank').addClass('selected');
															     });
														     }}
														>
															<div className="col-lg-1 col-xs-16 padded">
																<div className="ui radio checkbox">
																	<input type="radio"
																	       name="bank_id"
																	       tabIndex="0"
																	       className="hidden"
																	       value={bank.id}
																	       checked={i === 0 ? "checked" : ""}
																	       onChange={() => {

																	       }}
																	/>
																	<label/>
																</div>
															</div>
															<div className="col-lg-4 col-xs-16 padded">
																<img src={bank.logo_url} alt="" style={{maxWidth: '100%'}}/>
															</div>
															<div className="col-lg-11 col-xs-16 padded">
																<b>ธนาคาร : {bank.code_name}</b>
																<br/>
																<b>ชื่อบัญชี : {bank.name}</b>
																<br/>
																<b>เลขที่บัญชี : {bank.no}</b>
															</div>
														</div>
													)
												});
											})
										}
										<h4 className="ui header">อัพโหลดรูปสลิป</h4>
										<Form2FileUpload
											upload_type="single_image"
											input_name="payment_by_bank_transfer_draft_file_upload"
											removeInputName="remove_payment_by_bank_transfer_draft_file_upload"
											isCanRemove={true}
											value={this.state.product && this.state.product.file_upload ? this.state.product.file_upload.filename : ''}
											image_url={this.state.product && this.state.product.file_upload ? this.state.product.file_upload.url : ''}
											key_file_data="file_upload"
											width="100%"
											height="250px"
											mode={"view"}
											url={process.env.REACT_APP_HOST_URL2 + "/api/file/upload?type=upload"}
											// onRemove={this.state.signature && this.state.signature.file_upload ? () => {
											// 	return false;
											// } : false}
											onUploadDone={(file) => {
												// console.log(file);
												return true;
											}}
										/>
										<br/>
										<div className="ui message yellow">โปรดโอนเงินให้ตรงตามยอดชำระเงิน (฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price.format(2)})</div>
										<div className="field">
											<label htmlFor="">จำนวนเงินที่โอน</label>
											<div className="ui right labeled input">
												<input type="number"
												       defaultValue={0 + ''}
												       step="any"
												       name="total_net_price"
												       onFocus={(event) => event.target.select()}
												       className={"custom-input"}
												/>
												<div className="ui label basic" style={{marginLeft: '-.833em'}}>บาท</div>
											</div>
										</div>
										<div className="field">
											<label htmlFor="">วันที่โอนเงิน</label>
											<div data-form_calendar="date"
											     data-input_name="date_transfer"
											     data-placeholder="วันที่โอนเงิน"
											     data-locale="th"
											     data-initialdate="now"
											/>
										</div>
										<div className="field">
											<label htmlFor="">เวลา</label>
											<div className="ui right labeled input middle-xs">
												<select className="ui dropdown fluid"
												        name="hour"
												        data-form2_setup=""
												        defaultValue={this.state.hours[(new Date().getHours())]}>
													<option value="">ชั่วโมง</option>
													{
														this.state.hours.map((hour, i) => {
															return <option key={i}>{hour}</option>
														})
													}
												</select>
												<span style={{
													fontSize: 20,
													marginLeft: 10,
													marginRight: 10,
													marginTop: -5,
													display: 'inline-block'
												}}> : </span>
												<select className="ui dropdown fluid"
												        name="minute"
												        data-form2_setup=""
												        defaultValue={this.state.minute[(new Date().getMinutes())]}>
													<option value="">นาที</option>
													{
														this.state.minute.map((hour, i) => {
															return <option value={hour} key={i}>{hour}</option>
														})
													}
												</select>
											</div>
										</div>
										<div className="ui error message"/>
										<br/>
										<button className="ui button fluid green big" type="submit">ยืนยันสั่งซื้อ</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<>
				<div style={{
					padding: '1.3rem',
					height: '100%',
				}}
				     className="ui form flex between-xs"
				>
					<div>
						<h2 className="ui header">
							<i className="shopping bag icon"
							   style={{
								   fontSize: '1.4rem',
								   lineHeight: 1,
							   }}/>ตะกร้าสินค้า
						</h2>
						{
							!this.state.sale_order_via_e_commerce
							|| this.state.sale_order_via_e_commerce.sale_order.sale_order_item.length === 0
								?
								<div className="cartItem">
									ไม่มีสินค้า
								</div>
								: null
						}
						{
							this.state.sale_order_via_e_commerce
								?
								this.state.sale_order_via_e_commerce.sale_order.sale_order_item.map((sale_order_item, i) => {
									let qty = parseFloat(sale_order_item.sale_order_item_product_display_link._quantity);
									if (qty < 0 || isNaN(qty)) {
										qty = 0;
									}
									let $input;
									return (
										<div className="column"
										     key={i}
										     ref={(ref) => {
											     $input = window.$(ref).find('input[name="quantity"]');
										     }}
										     style={{
											     padding: '0',
										     }}>
											<div className="cartItem">
												<div className="flex row wrap middle-xs">
													<div>
														<h4>{sale_order_item.sale_order_item_product_display_link.product_display_link.product_display.name}</h4>
													</div>
													<div className="right floated">
														<DisplayPrice item={sale_order_item}/>
													</div>
												</div>
												<div className="controlCartItem">
													<div className="field flex row middle-xs center-xs">
														<button type="button"
														        className="ui button icon basic manageItem"
														        onClick={() => {
															        qty = qty - 1;
															        if (qty <= 0) {
																        qty = 0;
															        }
															        $input.val(qty);
															        this.changeQty(
																        sale_order_item.sale_order_item_product_display_link.product_display_link_id,
																        qty
															        )
														        }}>
															<i className="minus icon"/>
														</button>
														<input type="text"
														       className="transparent manageItem"
														       name="quantity"
														       placeholder="Quantity"
														       value={qty}
														       readOnly={true}
														       autoComplete="off"
														       style={{
															       width: '40%',
															       fontWeight: 'bold',
															       marginRight: '0.2rem',
														       }}
														       onChange={(event) => {
															       // console.log(event.target.value);
															       let qty = parseFloat(event.target.value);
															       if (isNaN(qty)) {
																       qty = 0;
															       }
															       $input.val(qty);
															       this.changeQty(
																       sale_order_item.sale_order_item_product_display_link.product_display_link_id,
																       qty
															       )
														       }}
														       onFocus={(e) => {
															       $input.select();
														       }}
														/>
														<button type="button"
														        className="ui button icon basic manageItem"
														        onClick={() => {
															        qty = qty + 1;
															        $input.val(qty);
															        this.changeQty(
																        sale_order_item.sale_order_item_product_display_link.product_display_link_id,
																        qty
															        )
														        }}>
															<i className="plus icon"/>
														</button>
													</div>
												</div>
											</div>
										</div>
									)
								})
								: null
						}
					</div>
					<div>
						{
							this.state.sale_order_via_e_commerce
								?
								<div>
									<div className="cartItem flex row wrap middle-xs between-xs">
										<div>
											<h3>ทั้งหมด</h3>
										</div>
										<div>
											<h3>฿{this.state.sale_order_via_e_commerce.sale_order.summary.total_net_price.format(2, 3, {pretty: true})}</h3>
										</div>
									</div>
									<a className="ui button fluid big green"
									   href="/checkout"
									   style={{marginTop: '1rem'}}>
										ยืนยันสั่งซื้อ
									</a>
								</div>
								: null
						}
					</div>
				</div>
			</>
		);
	}

	render() {
		if (this.props.mode === 'list') {
			return this.renderList();
		}

		return null;
	}
}