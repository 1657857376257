import React from 'react';

export default class ForexPerformance extends React.Component {
	constructor(props) {
		super(props);
		this.state = {}
	}

	componentDidMount() {
		this.update();
	}

	update() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/forex-performance',
			data: {},
			done: (response) => {
				setTimeout(() => {
					this.update();
				}, 5000);
				if (!response.success) {
					return false;
				}
				let min = 99999;
				let max = -99999;
				window.$.each(response.data, (i, symbol) => {
					symbol.value = parseFloat(symbol.value);
					if (symbol.value > max) {
						max = symbol.value;
					}
					if (symbol.value < min) {
						min = symbol.value;
					}
				});
				this.setState({
					symbols: response.data,
					min: min,
					max: max,
				});
			}
		});
	}

	render() {
		if (!this.state.symbols) {
			return null;
		}

		let height = window.$('body').height() * 0.3;

		return (
			<div style={{
				// background: '#f8f8f8',
				background: 'linear-gradient(-180deg, #f8f8f8 0%, #EEEDED 100%)',
				padding: '2rem 0',
				borderRadius: '1.5rem',
			}}>
				<h1 style={{textAlign: 'center'}}><span style={{
					borderRadius: '0.5rem 0.5rem',
					// background: '#1c831c',
					background: 'linear-gradient(-90deg, #1c831c 0%, #166316 100%)',
					padding: '0.1rem 0.5rem',
					color: '#FFF',
				}}>Forex</span> Strength Indicator</h1>
				<div className="flex row center-xs" style={{
					// padding: '0 0 4rem 0'
				}}>
					<div className="col-lg-8 col-xs-16 flex row middle-xs center-xs" style={{
						flex: 1,
					}}>
						{
							this.state.symbols.map((symbol, i) => {
								symbol.value = parseFloat(symbol.value);

								// let d = 100 - ((symbol.value / this.state.max) * 100);
								let d = i - 10;
								let d2 = d + 20;
								let main = window.tinycolor('#1c7c1c').darken(d).toString();
								let main2 = window.tinycolor('#1c7c1c').darken(d2).toString();

								return (
									<div key={i}
									     className="flex"
									     style={{
										     position: "relative",
										     height: height,
										     flexDirection: 'column-reverse',
										     width: (100 / this.state.symbols.length) + '%',
										     margin: '0 2%',
									     }}>
										<div style={{
											borderRadius: '0 0 0.5rem 0.5rem',
											background: main,
											color: '#FFF',
										}}>
											{symbol.symbol}
										</div>
										<div style={{
											width: '100%',
											background: main2,
											borderRadius: '0.5rem 0.5rem 0 0',
											color: '#FFF',
											height: (height * 0.9) * (symbol.value / this.state.max),
										}}>
											{symbol.value.format(1)}
										</div>
									</div>
								)
							})
						}
					</div>
					<div>

					</div>
				</div>
			</div>
		)
	}
}
