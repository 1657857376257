import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
} from "react-router-dom";
import './App.css';
import Register from "./Register";
import SubmitConfirmEmail from "./SubmitConfirmEmail";
import ProductDisplay from "./ProductDisplay";
import Cart from "./Cart";
import SaleOrderViaECommerce from "./SaleOrderViaECommerce";
import MyProfile from "./MyProfile";

window.Global2 = {
	getMyProfile: () => {
		let data = window.Global.getCookie('relationship_account');
		window.relationship_account = data ? JSON.parse(data) : false;
		return window.relationship_account;
	},
	loginCheck: (url) => {
		if (!window.Global2.getMyProfile().id) {
			window.Global.messageTop({
				type: 'negative',
				message: "โปรดเข้าสู่ระบบเพื่อเข้าใช้งาน",
			});
			return false;
		}
		window.location.href = url;
	},
	getQuantity: (sale_order_item) => {
		if (sale_order_item.sale_order_item_product_display_link._quantity) {
			return sale_order_item.sale_order_item_product_display_link._quantity.format(2, 3, {pretty: true}).toString()
		}
		return sale_order_item.quantity2;
	}
};

window.Global.ajax = function (object) {
	if (typeof (object.method) == 'undefined')
		object.method = 'POST';
	if (typeof (object.dataType) == 'undefined')
		object.dataType = 'json';
	if (typeof (object.contentType) == 'undefined')
		object.contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
	if (object.data) {
		//object.data.api_key = '3WEgGuFJpPedJX84sbAzaWxv';
	} else {
		object.data = {};
	}

	let headers = object.headers ? object.headers : {};
	if (window.Global2.getMyProfile()) {
		headers['X-B49-CLIENT'] = window.Global2.getMyProfile().token;
	}

	return window.$.ajax({
		type: object.method,
		data: object.data,
		contentType: object.contentType,
		url: process.env.REACT_APP_HOST_URL + object.url,
		headers: headers,
		beforeSend: function () {
			if (typeof (object.beforeSend) != 'undefined') {
				object.beforeSend();
			}
		},
		dataType: object.dataType
	}).done(function (response, textStatus, jqXHR) {
		// console.log(response);
		if (response.error && response.message) {
			// window.$.each(response.message, function (i, message) {
			// 	if (message['is_require_authentication']) {
			// 		window.location = '/signout';
			// 		return false;
			// 	}
			// })
		}
		if (typeof object.done == 'function')
			object.done(response);
	}).fail(function (jqXHR, textStatus, errorThrown) {
		//console.log(jqXHR, typeof object.fail == 'function');
		if (typeof object.fail == 'function')
			object.fail(jqXHR);
	});
};
window.Global.google_recaptcha_key = '';

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			qs: window.Global.getAllQueryStrings(),
		};
	}

	componentDidMount() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/v1/e-commerce/me',
			data: {},
			done: (response) => {
				if (!response.success) {
					this.setState({
						is_loading: false,
					});
					return false;
				}
				this.setState({
					is_loading: false,
					relationship_account: response.relationship_account,
				}, () => {
					window.relationship_account = response.relationship_account;
				});
			}
		});
	}

	render() {
		return (
			<Router>
				<div className="App flex">
					<header className="App-header">
						<div className="ui container form" style={{padding: 0}}>
							<div className="flex row middle-xs wrap">
								<Link to={'/'}
								      style={{
									      color: 'gold',
									      fontFamily: "'Zen Dots', cursive",
									      fontWeight: "bold",
									      letterSpacing: "1px",
								      }}
								      className="logo">A2NFX</Link>
								{
									!window.Global2.getMyProfile()
										?
										<>
											<Link to="/login">เข้าสู่ระบบ</Link>
											{/*<Link to="/register">ลงทะเบียน</Link>*/}
										</>
										:
										<>
											<Link to="/history">
								<span
									style={{
										fontSize: '0.7rem',
										color: '#FFF'
									}}>สวัสดี {window.relationship_account.username.substr(0, 15)}</span>
												<br/>ประวัติการสั่งซื้อ
											</Link>
											<Link to="/my-profile">ข้อมูลส่วนตัว</Link>
											<a href="/"
											   onClick={(e) => {
												   e.preventDefault();
												   window.Global.createCookie(
													   'relationship_account',
													   '',
													   365
												   );
												   window.location.href = '/';
											   }}>ออกจากระบบ</a>
										</>
								}
								<div className="ui left icon input SearchHeader right floated col-lg-4">
									<i className="search icon"/>
									<input key={window.Global.getRandomInt(1, 10000000) + 'ddd'}
									       type="text"
									       style={{background: '#ffffff4a', color: '#FFF'}}
									       placeholder="ค้นหา"
									       name="search"
									       defaultValue={window.Global.getAllQueryStrings().search}
									       onKeyUp={(e) => {
										       if (e.keyCode === 13) {
											       window.location = '/?search=' + e.target.value;
										       }
									       }}
									/>
								</div>
							</div>
						</div>
					</header>
					<div className="flex"
					     style={{
						     height: '100%',
						     flex: 'auto',
					     }}>
						<Switch>
							<Route path="/register">
								<Register mode={'register'}/>
							</Route>
							<Route path="/login">
								<Register mode={'login'}/>
							</Route>
							<Route path="/forgot-password">
								<Register mode={'forgot-password'}/>
							</Route>
							<Route path="/confirm-email">
								<SubmitConfirmEmail/>
							</Route>
							<Route path="/product/create">
								<ProductDisplay mode="create"/>
							</Route>
							<Route path="/product/:product_display_id/edit" component={ProductDisplay}/>
							<Route path="/checkout">
								<Cart mode="list"/>
							</Route>
							<Route path="/history">
								<SaleOrderViaECommerce mode="list"/>
							</Route>
							<Route path="/my-profile">
								<MyProfile mode="view"/>
							</Route>
							<Route path="/">
								<ProductDisplay mode="list"/>
							</Route>
						</Switch>
					</div>
					<footer style={{
						padding: '1rem',
						borderTop: '1px solid #e6e6e624',
						color: '#ffffff78',
						textAlign: 'center',
						background: '#282c34',
					}}>
						<div>Copyright © 2021 A2NFX</div>
						<div style={{fontSize: '0.9rem'}}>การลงทุนมีความเสี่ยง ผู้ลงทุนควรทำความเข้าใจลักษณะสินค้า
							เงื่อนไขผลตอบแทนและความเสี่ยงก่อนตัดสินใจลงทุน ผลการดำเนินงานในอดีต
							มิได้เป็นสิ่งยืนยันถึงผลการดำเนินงานในอนาคต
						</div>
					</footer>
				</div>
			</Router>
		);
	}
}
