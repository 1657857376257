import React from 'react';
import {ZeroWorkEditor} from "react-spaceship-web";
// import Cart from "./Cart";
// import DisplayPrice from "./DisplayPrice";
import ForexPerformance from "./ForexPerformance";

export default class ProductDisplay extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			isUpdatingCart: false,
			mode: this.props.mode,
		};

		if (this.props.match
			&& this.props.match.params.product_display_id
		) {
			this.state.mode = 'edit';
		}

		window.ProductDisplay = this;
	}

	componentDidMount() {
		if (this.state.mode === 'list') {
			this.fetch();
		}
		if (this.state.mode === 'edit') {
			window.Global.ajax({
				method: 'GET',
				url: '/api/e-commerce/v1/product_display',
				data: {
					product_display_id: this.props.match.params.product_display_id,
					request_data_product_display_using_filter: true,
				},
				done: (response) => {
					if (!response.success) {
						return false;
					}
					this.setState({
						is_loading: false,
						product_display: response.product_display[0]
					}, () => {

					});
				}
			});
		}
	}

	fetch() {
		window.Global.ajax({
			method: 'GET',
			url: '/api/e-commerce/v1/product_display',
			data: {
				search_query: window.Global.getAllQueryStrings().search,
			},
			done: (response) => {
				if (!response.success) {
					return false;
				}
				this.setState({
					is_loading: false,
					product_displays: response.product_display
				}, () => {

				});
			}
		});
	}

	makeButtonAdded(product_display_link_id) {
		window.$('#addProductToCart-' + product_display_link_id)
			.addClass('disabled')
			.removeClass('green')
			.addClass('black')
			.html('<i class="check icon"/>เพิ่มลงตะกร้า');
	}

	makeButtonNoAdded(product_display_link_id) {
		window.$('#addProductToCart-' + product_display_link_id)
			.removeClass('disabled')
			.addClass('green')
			.removeClass('black')
			.html('เพิ่มลงตะกร้า');
	}

	renderList() {
		return (
			<>
				<div className="ui container"
				     style={{
					     marginTop: '2rem'
				     }}
				     ref={(ref) => {
					     this.$content = window.$(ref);
				     }}>
					<div className="flex row wrap padded">
						<div className="col-xs-16 padded">
							{/*<br/>*/}
							<ForexPerformance/>
							<br/><br/><br/>
						</div>
						<div className="col-xs-16 padded">
							<div className="highlight2 flex row wrap">
								<div className="col-xs-16 col-lg-7">
									<p>เรียนรู้เกี่ยวกับ</p>
									<h1 style={{marginTop: 0}}>EA Rescue</h1>
									<h4>
										<p className="indent">เป็น EA ประเภท Semi Auto
											ใช้สำหรับการช่วยให้การเทรดมีความสะดวกมากยิ่งขึ้น ไม่ว่าจะเป็นในเรื่อง การตั้ง TP SL
											อัตโนมัติให้ หรือ เก็บกำไร ตัดขาดทุน เป็น USD</p>
										<p className="indent">อีกทั้งยังมีฟังชั่น ช่วยเหลือในการแก้ไม้ ที่ผู้ใช้งานสามารถ Design
											ออกแบบเองได้ตามความเหมาะสม โดย 28 คู่เงินหลัก และ ทองคำ สามารถ
											กำหนดระยะได้เองตามความต้องการ</p>
										<p className="indent">และที่สำคัญยังมีระบบ การรวบไม้เก็บกำไร
											อีกด้วยเพื่อช่วยให้การแก้ไม้มีประสิทธิภาพมากยิ่งขึ้น</p>
									</h4>
								</div>
								<div className="col-xs-16 col-lg-9">
									<div className="flex row center-xs">
										<img src="/assets/images/ea.jpg" alt=""/>
									</div>
								</div>
							</div>
							<div className="highlight2">
								<h1 style={{marginTop: 0}}>รายละเอียด</h1>
								<h4 className="indent">
									<p>ในระบบของ EA มีทั้งหน้า Dashboard แสดงผลกำไรขาดทุน ว่า ณ เวลานี้ กำไร / ขาดทุนรวมเท่าไหร่
										เราได้เข้า Order คู่ไหนบ้าง กำไร / ขาดทุน แต่ละคู่ เท่าไหร จำนวน lots รวม จำนวม Order รวม
										มีค่า Commission เท่าไหร่ (สำหรับบัญชีประเภท ECN) และ Swap ที่เกิดขึ้นนะตอนนี้ ให้ง่าย และ
										สะดวกแก่การ monitor</p>
								</h4>
								<div className="flex row center-xs">
									<img src="/assets/images/ea-rescue/01.png" alt=""/>
								</div>
								<h4>
									<p style={{textAlign: 'center'}}>อีกทั้งยังมีปุ่มกดปิด Order และ เปิด กราฟ คู่นั้น ๆ ออกมาดูได้
										โดยไม่ต้องไปเลื่อนหาคู่กราฟ</p>
								</h4>
								<div className="flex row center-xs">
									<img src="/assets/images/ea-rescue/02.png" alt=""/>
								</div>
								<h4>
									<p style={{textAlign: 'center'}}>การใช้งานแบบที่ 1 กรณีไม่ใช้การแก้ไม้ (Use Mode เป็น Manual)</p>
								</h4>
								<div className="flex row wrap">
									<div className="col-lg-8 col-xs-16 flex row center-xs">
										<img src="/assets/images/ea-rescue/03.png" alt=""/>
									</div>
									<div className="col-lg-8 col-xs-16">
										<p>
											Take Profit คือ เก็บระยะกำไรตามระยะ point
											<br/>Stop Loss คือ ตั้งจุดยอดแพ้ ตามระยะ point
											<br/>Profit คือ เก็บกำไรจำนวน USD เฉพาะคู่นั้น ๆ
											<br/>Profit / Multi (กดที่ Profit 1 ครั้ง) คือ เก็บกำไรเมื่อผลรวมทุกคู่เท่ากับ
											<br/>Cut Loss คือ ตัดขาดทุนจำนวน USD
											<br/><br/>
											การกันกำไร จะกันเฉพาะในคู่นั้น ๆ
											<br/>Tailing Stop คือ ระยะ ที่จะเริ่มมีการกันกำไร
											<br/>Tp Tailing คือ กันกำไรระยะจำนวน ห่างจาก กราฟ ณ ปัจจุบัน
											<br/><br/><b>ตัวอย่าง... (จากภาพ)</b>
											<br/>Tailing Stop 300
											<br/>TP Trailing 200
											<br/>คือ จะเริ่มมีการกันกำไร เมื่อ Order ของเรากำไร 300 จุด จะมี SL มากันกำไรที่ 200
											จุด และทุกครั้งที่กราฟขยับขึ้นไปต่อ
											<br/>SL กันกำไรของเราจะ ขยับห่างจากราคา ณ ปัจจุบัน 200 ไปเรื่อย ๆ
										</p>
									</div>
								</div>
								<br/><br/>
								<div className="flex row wrap">
									<div className="col-lg-8 col-xs-16 flex row center-xs">
										<img src="/assets/images/ea-rescue/04.png" alt=""/>
									</div>
									<div className="col-lg-8 col-xs-16">
										<p>
											<br/>Trailing $ คือ กำไรจำนวน USD ที่จะเริ่มมีการกันกำไร
											<br/>TP Trailing $ คือ การเริ่มกันกำไร เมื่อ กำไรไปถึงเป้าหมายที่กำหนดแล้ว และ
											จะกันกำไรไว้จำนวน USD
											<br/><br/><b>ตัวอย่าง... (จากภาพ)</b>
											<br/>Trailing $ 20
											<br/>TP Trailing $ 15
											<br/>คือ เมื่อกำไร 20 USD จะเริ่มมีการกันกำไรไว้ที่ 15 USD และกำไรจะไหลตามไปเรื่อย ๆ
											เมื่อกำไรของ คู่เงินนั้น ๆ เพิ่มมากขึ้น
										</p>
									</div>
								</div>
								<br/><br/>
								<div className="flex row wrap">
									<div className="col-lg-9 col-xs-16 flex row center-xs">
										<div>
											<a href="/assets/images/ea-rescue/05.png">
												<img src="/assets/images/ea-rescue/05.png" alt=""/>
											</a>
										</div>
									</div>
									<div className="col-lg-7 col-xs-16">
										<h4>การใช้งานแบบที่ 2 Mode แบบแก้ไม้ (Use Mode เป็น Auto)</h4>
										<p>
											การแก้ไม้จะเป็นการแก้ไม้แบบเปิดตามระยะและ Multi คูณ Order ไปเรื่อย ๆ ตามระยะ
											<br/><br/><b>รายละเอียด</b>
											<br/>สามารถตั้งค่าให้ 28 คู่เงินหลัก ได้ที่ระยะแตกต่าง เพื่อประสิทธิภาพการทำงานที่ดี
											(ส่วนที่ 1)
											<br/>สามารถเพิ่มได้อีก 6 ผลิตภัณเทรด (โดยสามารถระบุเพิ่มได้เอง) ในส่วน ที่ 2
											<br/>และสามารถ เพิ่ม ลูกเล่นเสริมเข้าไปได้อีกนิด คือ ระยะ จะมีการ Mark up ได้ (ส่วนที่
											3)
											<br/><br/><b>ยกตัวอย่างเช่น</b>
											<br/>EURUSD ตั้งไว้ที่ 300 และในส่วนที่ 3 หรือ +Up Distance 20
											<br/>การทำงานคือ จะเริ่มแก้ไม้ EUR USD ที่ 300 + 20
											<br/>คือ 300 320 340 360 .... ไปเรื่อย ๆ นั่นเอง
										</p>
										<p>
											<br/><b>ส่วนการตั้งค่า แก้ไม้</b>
											<br/>Max Order คือ ออกไม้แก้ จำนวนไม่เกิน
											<br/>X Multi คือ การคูณ Lots Order
											<br/><br/>
											<b>ตัวอย่าง... EUR USD แก้ไม้ที่ระยะ 300 Multi 1.5</b>
											<br/>สมมุติโดนลาก 900 จุด ออก lot ไม้แรก 1 Lots
											<br/>จะเกิด Order รวมทั้งหมด ดังนี้
											<br/>ไม้ที่ 1 	1 	Lots ที่ระยะ 0
											<br/>ไม้ที่ 2 	1.5 	Lots ที่ระยะ 300 point (จากไม้แรก)
											<br/>ไม้ที่ 3 	2.25 	Lots ที่ระยะ 600 point (จากไม้แรก)
											<br/>ไม้ที่ 4 	3.38 	Lots ที่ระยะ 900 point (จากไม้แรก)
											<br/><br/>
											Distance คือ ระยะแก้ไม้ คู่อื่น ๆ นอกจาก 28 คู่หลัก และ 6 คู่ที่ ระบุไว้
											<br/>
											<br/>กรณีใช้ทั้งโหมดแก้ไม้ และ Trailing กันกำไร
											<br/>การกันกำไรจะกันกำไร แบบ Trailing กลุ่ม ทุก ๆ การรวบ จะรวบเก็บเป็นชุดหมด
										</p>
									</div>
								</div>
								<h4>
									<p style={{textAlign: 'center'}}>หลังตั้งค่าเสร็จ กดรูปยิ้ม เพื่อย่อหน้าต่าง และ save ค่าการใช้งาน</p>
								</h4>
								<div className="flex row center-xs">
									<img src="/assets/images/ea-rescue/06.png" alt=""/>
								</div>

								<br/><br/>
								<div className="flex row wrap">
									<div className="col-lg-9 col-xs-16 flex row center-xs">
										<div style={{padding: '0 2rem 0 2rem'}}>
											<img src="/assets/images/ea-rescue/07.png" alt=""/>
										</div>
									</div>
									<div className="col-lg-7 col-xs-16">
										<h4>การตั้งค่าอื่น ๆ ประกอบด้วย 5 ส่วนหลัก ๆ </h4>
										<p>
											<b>General Setting</b>
											<br/>Dash Board Update คือ เวลาที่ Dash Board Update เป็น mil sec.
											<br/>Currency Per Select คือ คู่เงินที่สามารถใช้ในระบบได้ (ถ้าคู่ที่ต้องการใช้ไม่มี สามารถ กรอกเพิ่มได้)

											<br/><b>Settings Manage Size</b>
											<br/>เป็นส่วนปรับแต่งขนาด Size ตัวอักษร และ สีของ หน้าจอแสดงผล

											<br/><b>Setting Graph and Dash Board Status Order</b>
											<br/>เป็นส่วนการแสดงผลหน้าจอ Dash Board และ เปิดปิดการแสดงภาพกราฟ (แนะนำว่าปิดไว้ดีกว่า เพราะ ทำให้การประมวลผลและดึงทรัพยากรของ VPS ลดลง)

											<br/><b>Setting Save Close Drawdown</b>
											<br/>เป็นโหมดการตั้งควบคุมความเสี่ยง
											<br/>Use คือ เปิดปิดการใช้งาน True คือใช้งาน False คือไม่ใช้งาน
											<br/>Input คือ จำนวนเงินที่เราต้องการตั้งว่าเราจะลงทุนเท่าไหร่
											<br/>Money Invest of Drawdown คือ ระบบจะทำการ เครีย Order ทั้งหมดเมื่อ Draw down ลดลงถึง เพื่อ ควบคุมความเสี่ยง และ ตัดขาดทุน

											<br/><br/><b>ยกตัวอย่าง</b>
											<br/>Balance ของพอต 10630,000 USD
											<br/>ตั้ง input ที่ 10,000 USD
											<br/>Money Invest of Drawdown ที่ 50%
											<br/><br/>เมื่อพอตมีการติดลบ 5000 จะทำการเครีย Order ทั้งหมด เพื่อป้องกันและรักษาทุนที่เหลือไว้
										</p>
										<div className="flex row center-xs">
											<div style={{padding: '0 2rem 0 2rem'}}>
												<img src="/assets/images/ea-rescue/08.png" alt=""/>
											</div>
										</div>
										<p className="indent">
											<br/>สามารถประยุกข์ใช้ได้ดีในการควบคุมทุน เพราะ บางที การเทรดของเรา เมื่อทำกำไรมาแล้ว เรามักจะเผลอ และ เอากำไรที่ได้มาร่วมเล่นด้วย พอเกิดจังหวะที่ผิดพลาด และเกิดความเสียหาย (โดนลาก) บางที ก็กลายเป็นว่า พอตทั้งพอต ก็อาจจะหายไปหมดเลยก็ได้ เพราะฉะนั้นระบบนี้สามารถมาช่วยเหลือวินัยในการเทรดของผู้ใช้ได้ดี

											<br/><br/><b>Setting Save Amount Order</b>
											<br/>เป็นโหมด ช่วยเหลือ กรณีการแก้ไม้ ให้สามารถรวม Order ได้เร็วมาขึ้น
											<br/>Use คือ เปิดปิดการใช้งาน True คือใช้งาน False คือไม่ใช้งาน
											<br/>Start Save Amount Order คือ เมื่อจำนวนไม้แก้ ออกไปถึงจำนวนที่ตั้ง
											<br/>Keep When Profit Run คือ จะรีบรวมทันทีเมื่อกำไรถึงที่กำหนด

											<br/><br/><b>Setting Magic Number</b>
											<br/>ส่วนนี้เป็นการตั้ง กรณีรัน EA ไว้ในหลายคู่เงิน แต่สำหรับ EA ตัวนี้ ไม่จำเป็นต้องเปลี่ยนค่าใด ๆ เพราะ วางในกราฟคู่เดียว ก็สามารถทำงานได้ ทุก ๆ คู่เงิน
										</p>
									</div>
								</div>
							</div>
							<div className="highlight2">
								<div>
									<h2 style={{textAlign: 'center', marginBottom: '1rem'}}>ดาวโหลดไฟล์ EA </h2>
								</div>
								<div className="flex row wrap middle-xs center-xs">
									<a href="/assets/download/MT4/Rescue-Pro.ex4"
									   target="_blank"
									   className="ui button green big" style={{marginRight: '2rem'}}>MT4</a>
									<a href="/assets/download/MT5/Rescue-Pro.ex5"
									   target="_blank"
									   className="ui button green big">MT5</a>
								</div>
							</div>
							<br/><br/><br/>
						</div>
					</div>
				</div>
			</>
		)

		// if (this.state.is_loading) {
		// 	return null;
		// }
		//
		// return (
		// 	<>
		// 		<div className="ui container"
		// 		     style={{
		// 			     marginTop: '2rem'
		// 		     }}
		// 		     ref={(ref) => {
		// 			     this.$content = window.$(ref);
		// 		     }}>
		// 			<div className="flex row wrap padded">
		// 				<div className="col-lg-11 col-xs-16 padded"
		// 				     style={{}}>
		// 					<h1>รายการสินค้า</h1>
		// 					<div className="flex row wrap one column computer padded">
		// 						{
		// 							this.state.product_displays.length === 0
		// 								?
		// 								<div className="column">
		// 									<div className="ui button big fluid">
		// 										ไม่พบสินค้า
		// 									</div>
		// 								</div>
		// 								: null
		// 						}
		// 						{
		// 							this.state.product_displays.map((product_display, i) => {
		// 								return (
		// 									<div className="column"
		// 									     key={i}
		// 									     style={{
		// 										     // padding: '0 0 1rem 1rem',
		// 									     }}>
		// 										<div className="light-grey flex row wrap"
		// 										     style={{
		// 											     padding: '1.5rem',
		// 											     minHeight: '12rem',
		// 											     marginBottom: '1.5rem',
		// 										     }}>
		// 											<div className="col-lg-12 col-xs-16">
		// 												<div>
		// 													<h3>{product_display.name} {
		// 														window.relationship_account
		// 														&& window.relationship_account.is_admin
		// 															?
		// 															<a href={"/product/" + product_display.id + '/edit'}
		// 															   className="ui button mini icon"
		// 															   style={{marginLeft: '1rem'}}>
		// 																<i className="edit icon"/>
		// 															</a>
		// 															: null
		// 													}</h3>
		// 												</div>
		// 											</div>
		// 											<div className="col-lg-4 col-xs-16">
		// 												<div style={{
		// 													margin: '0.5rem 0 0.8rem 0',
		// 													textAlign: 'center',
		// 												}}>
		// 													<DisplayPrice
		// 														item={product_display.product_display_link[0].product.sell_price[0].default_item_sell}
		// 													/>
		// 												</div>
		// 												<div className="ui button green fluid addProductToCartButton"
		// 												     id={"addProductToCart-" + product_display.product_display_link[0].id}
		// 												     data-product_display_link_id={product_display.product_display_link[0].id}
		// 												     onClick={() => {
		// 													     window.ProductDisplay.makeButtonAdded(
		// 														     product_display.product_display_link[0].id
		// 													     );
		// 													     window.Cart.addProductToCart(
		// 														     product_display.product_display_link[0].id,
		// 														     1
		// 													     );
		// 												     }}>
		// 													เพิ่มลงตะกร้า
		// 												</div>
		// 											</div>
		// 										</div>
		// 									</div>
		// 								)
		// 							})
		// 						}
		// 					</div>
		// 					{
		// 						window.relationship_account
		// 						&& window.relationship_account.is_admin
		// 							?
		// 							<div style={{
		// 								margin: '1rem 0'
		// 							}}>
		// 								<a href="/product/create"
		// 								   className="ui button basic fluid">
		// 									สร้างสินค้า
		// 								</a>
		// 							</div>
		// 							: null
		// 					}
		// 				</div>
		// 				<div className="col-lg-5 col-xs-16 padded">
		// 					<div className="light-grey">
		// 						<Cart mode="list"/>
		// 					</div>
		// 				</div>
		// 				<div className="col-xs-16 padded">
		// 					<br/>
		// 					<ForexPerformance/>
		// 					<br/><br/><br/>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</>
		// );
	}

	render() {
		if (this.state.mode === 'list') {
			return this.renderList();
		}
		if (this.state.mode === 'edit') {
			if (!this.state.product_display) {
				return null;
			}
		}

		return (
			<>
				<div className="ui container padded"
				     ref={(ref) => {
					     this.$content = window.$(ref);
				     }}>
					<div className="flex row wrap center-xs middle-xs">
						<div className="col-xs-16 col-lg-12 light-grey"
						     style={{
							     padding: '1.5rem'
						     }}>
							<form className={"ui form"}
							      ref={(ref) => {
								      if (!ref) return;

								      let $form = window.$(ref);
								      $form.form2('setup');

								      if (this.state.mode === 'create') {
									      $form.form2('submit', {
										      method: 'POST',
										      url: '/api/product_display',
										      beforeSent: (object) => {
											      object.content_state = this.content_state.getContent();
											      return object;
										      },
										      loadingButton: $form.find('button.button.approve'),
										      onSuccess: () => {
											      window.location = '/';
										      },
										      onError: (response) => {
											      $form.find('.info.message').hide();
										      }
									      });
								      }

								      if (this.state.mode === 'edit') {
									      $form.form2('submit', {
										      method: 'PUT',
										      url: '/api/product_display',
										      beforeSent: (object) => {
											      object.product_display_id = this.state.product_display.id;
											      object.content_state = this.content_state.getContent();
											      return object;
										      },
										      loadingButton: $form.find('button.button.approve'),
										      onSuccess: () => {
											      window.location = '/';
										      },
										      onError: () => {
											      $form.find('.info.message').hide();
										      }
									      });
								      }
							      }}
							>
								<h2 className={"ui header"}>
									<i className="icon edit"/>{
									this.state.mode === 'create'
										? 'สินค้าใหม่'
										: 'แก้ไขสินค้า'
								}
								</h2>
								<div className="field">
									<label htmlFor="title">ชื่อสินค้า</label>
									<input type="text"
									       name={"product_name"}
									       autoComplete={"off"}
									       defaultValue={
										       this.state.mode === 'create'
											       ? ''
											       : this.state.product_display.name
									       }
									/>
								</div>
								<div className="field">
									<label htmlFor="title">ราคา</label>
									<input type="text"
									       name={"product_price"}
									       autoComplete={"off"}
									       defaultValue={
										       this.state.mode === 'create'
											       ? ''
											       : this.state.product_display.product_display_link[0].product.sell_price[0].default_item_sell.price
									       }
									/>
								</div>
								<div className="field">
									<label htmlFor="title">รายละเอียด</label>
									<div style={{
										background: '#FFF',
										padding: '1rem'
									}}>
										<ZeroWorkEditor mode={"create"}
										                uploadUrl={
											                // process.env.REACT_APP_HOST_URL + '/api/file/upload?type=upload'
											                'https://dev.b49erp.com/api/file/upload?type=upload'
										                }
										                content_state={
											                this.state.mode === 'create'
												                ? ''
												                : this.state.product_display.content_state
										                }
										                ref={(ref) => {
											                if (!ref) return;
											                this.content_state = ref;
											                // console.log(ref.getContent());
										                }}
										/>
									</div>
								</div>
								<div className="field">
									<label htmlFor="title">หมวดหมู่</label>
									<select name="product_category_id"
									        className="ui dropdown"
									        defaultValue={
										        this.state.mode === 'create'
											        ? ''
											        : this.state.product_display.product_display_using_filter[0].filter_id
									        }
									        data-form2_setup="">
										<option value="17">ทดสอบ</option>
										<option value="6">เสื้อยืด</option>
									</select>
								</div>
								<div className="field">
									<label htmlFor="title">อื่นๆ</label>
									<div className="ui checkbox" data-form2_setup="">
										<input id="is_disable"
										       type="checkbox"
										       tabIndex="0"
										       className="hidden"
										       name="is_disable"
										       defaultChecked={
											       this.state.mode === 'create'
												       ? ''
												       : this.state.product_display.is_disable
										       }
										/>
										<label htmlFor="is_disable">ปิดการใช้งานชั่วคราว</label>
									</div>
								</div>
								<p>
									*คำเตือน การลงทุนมีความเสี่ยง ผลงานในอดีตไม่ได้การันตีอนาคตนะ
								</p>
								<p>
									*การลงทุนมีความเสี่ยง ผู้ลงทุนควรทำความเข้าใจลักษณะสินค้า
									เงื่อนไขผลตอบแทนและความเสี่ยงก่อนตัดสินใจลงทุน
									ผลการดำเนินงานในอดีต มิได้เป็นสิ่งยืนยันถึงผลการดำเนินงานในอนาคต
								</p>
								<div className="field GoogleRecaptcha_root"/>
								<div className="ui error message"/>
								<button type={"submit"}
								        className={"ui button green fluid approve"}>
									บันทึก
								</button>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}
}
