import React from 'react';

export default class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			qs: window.Global.getAllQueryStrings(),
		};
	}

	componentDidMount() {
		window.Global.createCookie('relationship_account', '', -1);

		let referral = '';
		if (this.state.qs.referral) {
			referral = this.state.qs.referral;
		} else if (window.Global.getCookie('referral')) {
			referral = window.Global.getCookie('referral');
		}

		if (this.props.mode === 'register'
			&& referral
		) {
			window.Global.createCookie('referral', referral, 365);
			window.Global.ajax({
				method: 'GET',
				url: '/api/v1/e-commerce/checking-referral',
				data: {
					referral: referral
				},
				done: (response) => {
					if (!response.success) {
						return false;
					}
					this.setState({
						referral: referral,
						response_referral: response,
					});
				}
			});
		}
	}

	renderRegister() {
		return (
			<div className="secondBackground fluid">
				<div className="ui container padded">
					<div className="flex row wrap center-xs middle-xs">
						<div className="ui segment col-xs-16 col-lg-8">
							<form className={"ui large form"}
							      ref={(ref) => {
								      this.form = window.$(ref);
								      this.form.form2('submit', {
									      method: 'POST',
									      url: '/api/v1/e-commerce/relationship/account',
									      beforeSent: (object) => {
										      return object;
									      },
									      loadingButton: this.form.find('.button.approve'),
									      submitButton: this.form.find('.button.approve'),
									      onSuccess: (response) => {
										      window.Global.messageTop({
											      type: 'positive',
											      message: 'เข้าสู่ระบบสำเร็จ'
										      });
										      window.Global.createCookie(
											      'relationship_account',
											      JSON.stringify({
												      ...response.relationship_account,
												      relationship: {
													      name: response.relationship_account.relationship.name,
												      },
											      }),
											      365
										      );
										      window.location.href = '/';
									      }
								      })
							      }}
							>
								<h1 className="ui header">ลงทะเบียน</h1>
								<div className="field required">
									<label>อีเมล</label>
									<input type={"text"} name={"email"} required={true}/>
								</div>
								<div className="field required">
									<label>รหัสผ่าน</label>
									<input type={"password"} name={"password"}/>
								</div>
								<div className="field required">
									<label>บัญชีผู้ใช้งาน</label>
									<input type={"text"} name={"username"} required={true}/>
								</div>
								<div className="field">
									<label>เบอร์โทรศัพท์</label>
									<input type={"text"} name={"phone_number"}/>
								</div>
								{
									this.state.response_referral
										?
										<div className="field">
											<label>ผู้แนะนำ</label>
											<div className="ui label green">
												<i className="icon check"/>{this.state.response_referral.relationship_account.username}
												<input type={"hidden"}
												       name={"referral"}
												       value={this.state.referral}
												/>
											</div>
										</div>
										: null
								}
								<div className="field GoogleRecaptcha_root"/>
								<div className="ui error message"/>
								<div className={"field flex row middle-xs wrap"}>
									<button className="ui button fluid right labeled icon approve"
									        type="submit">
										<i className="icon arrow right"/>
										ลงทะเบียน
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		if (this.props.mode === 'register') {
			return this.renderRegister();
		}

		return (
			<div className="secondBackground fluid">
				<div className="ui container padded">
					<div className="flex row wrap center-xs middle-xs">
						<div className={"ui segment col-xs-16 col-lg-8"}>
							<form className={"ui large form"}
							      ref={(ref) => {
								      this.form = window.$(ref);
								      this.form.form2('submit', {
									      method: 'POST',
									      url: '/api/v1/relationship/account/login',
									      beforeSent: (object) => {
										      return object;
									      },
									      loadingButton: this.form.find('.button.approve'),
									      submitButton: this.form.find('.button.approve'),
									      onSuccess: (response) => {
										      window.Global.messageTop({
											      type: 'positive',
											      message: 'เข้าสู่ระบบสำเร็จ'
										      });
										      window.Global.createCookie(
											      'relationship_account',
											      JSON.stringify({
												      ...response.relationship_account,
												      relationship: {
													      name: response.relationship_account.relationship.name,
												      },
											      }),
											      365
										      );
										      window.location.href = '/';
									      }
								      })
							      }}
							>
								<h1 className="ui header">เข้าสู่ระบบ</h1>
								<br/>
								<div className="field">
									<label>อีเมล / ชื่อผู้ใช้งาน</label>
									<input type={"text"} name={"username"} required={true}/>
								</div>
								<div className="field">
									<label>รหัสผ่าน</label>
									<input type={"password"} name={"password"}/>
								</div>
								<div className="field GoogleRecaptcha_root"/>
								<div className="ui error message"/>
								<div className={"field"}>
									<button className="ui button fluid right labeled icon approve"
									        type="submit">
										<i className="icon arrow right"/>
										เข้าสู่ระบบ
									</button>
									<br/>
									<div className="right floated">
										<a className={"form-link"} href={'/forgot-password'}>ลืมรหัสผ่าน</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}