import React from 'react';

export default class DisplayPrice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
		};
	}

	render() {
		let default_sell_price = this.props.item;
		return (
			<div className="product-detail">
				<div className="flex row wrap middle-xs" style={{lineHeight: 1}}>
					<div style={
						default_sell_price.discount
						&& parseFloat(default_sell_price.discount) > 0
							? {
								textDecoration: 'line-through',
								fontSize: '1.2rem',
								color: '#999',
							}
							: {
								color: '#cf2424',
								fontSize: '1.2rem',
							}
					}
					>
						฿{default_sell_price.price.format(2, 3, {pretty: true})}
					</div>
					{
						default_sell_price.discount
						&& parseFloat(default_sell_price.discount) > 0
							? (
								<>
									<div style={{
										background: '#cf2424',
										color: '#fff',
										fontSize: '0.9rem',
										padding: '0.3rem 0.3rem',
										margin: '0 0.4rem',
										borderRadius: '0.2rem',
									}}>
										{
											default_sell_price.discount_type === "฿"
												? "฿"
												: null
										}{
										default_sell_price.discount.format(2, 3, {pretty: true})
									}{
										default_sell_price.discount_type === "%"
											? "%"
											: null
									} ส่วนลด
									</div>
								</>
							) : null
					}
				</div>
				{
					default_sell_price.discount
					&& parseFloat(default_sell_price.discount) > 0
						? <div style={{
							color: '#cf2424',
							fontSize: '1.2rem',
							margin: '0 0',
						}}>
							฿{default_sell_price.summary.total_net_price.format(2, 3, {pretty: true})}
						</div>
						: null
				}
			</div>
		);
	}
}