import React from 'react';
// import {Form2FileUpload} from "react-spaceship-web";

export default class MyProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_loading: true,
			qs: window.Global.getAllQueryStrings(),
		};

		window.SaleOrderViaECommerce = this;
	}

	componentDidMount() {

	}

	fetch() {
		// window.Global.ajax({
		// 	method: 'GET',
		// 	url: '/api/v1/e-commerce/me',
		// 	data: {
		//
		// 	},
		// 	done: (response) => {
		// 		if (!response.success) {
		// 			return false;
		// 		}
		// 		this.setState({
		// 			is_loading: false,
		// 			sale_order_via_e_commerce: response.sale_order_via_e_commerce,
		// 		}, () => {
		// 			this.$parent.find('.table').tablePagination({
		// 				data: response.sale_order_via_e_commerce,
		// 				count: response.count
		// 			});
		// 		});
		// 	}
		// });
	}

	renderView() {
		return (
			<div className="secondBackground fluid">
				<div ref={(ref) => {
					this.$parent = window.$(ref);
				}}
				     className="ui container form" style={{marginTop: '2.5rem'}}>
					{/*<h1 className="ui header" style={{marginBottom: '1.5rem'}}>ข้อมูลส่วนตัว</h1>*/}
					<div className="flex row wrap padded">
						<div className="col-lg-8 col-xs-16 padded">
							<div className="mainBackground mainBorderRadius">
								<h3 className="colorGold"><i className="icon user"/><b>ข้อมูลส่วนตัว</b></h3>
								<div style={{fontSize: '1.1rem'}}>
									<p><b>อีเมล</b> : {window.relationship_account.email}</p>
									<p><b>บัญชีผู้ใช้งาน</b> : {window.relationship_account.username}</p>
									<p><b>เบอร์โทรศัพท์</b> : {window.relationship_account.phone_number}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-8 col-xs-16 padded">
							<div className="mainBackground mainBorderRadius">
								<h4>พิเศษ... แนะนำเพื่อนรับทันที <span className="colorGold">฿100</span></h4>
								<h3 className="colorGold"><i className="icon linkify"/><b>ลิงค์แนะนำ</b></h3>
								<div className="ui field">
									<input type="text"
									       readOnly={true}
									       value={"https://a2nfx.com/register?referral=" + window.relationship_account.id}
									       onFocus={(e) => {
										       e.target.focus();
										       e.target.select();
										       try {
											       var successful = document.execCommand('copy');
											       // var msg = successful ? 'successful' : 'unsuccessful';
											       if (successful) {
												       window.Global.messageTop({
													       message: 'คัดลองลิงค์แนะนำเรียบร้อย'
												       });
											       }
										       } catch (err) {
											       console.log('Oops, unable to copy');
										       }
									       }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.mode === 'view') {
			return this.renderView();
		}

		return null;
	}
}