import React from 'react';

export default class SubmitConfirmEmail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			response_success_message: false,
		};
	}

	render() {
		return (
			<>
				<div className="ui container"
				     ref={(ref) => {
					     this.$content = window.$(ref);
				     }}>
					<div className="flex row wrap center-xs middle-xs">
						<div className="ui segment col-xs-16 col-lg-10">
							{
								this.state.response_success_message
									? <div className="ui success icon message">
										<i className="check icon"/>
										<div className="content">
											<h3 className="ui header" style={{textAlign: 'left'}}>
												{this.state.response_success_message}
											</h3>
										</div>
									</div>
									: null
							}
							{
								!this.state.response_success_message
									?
									<form className={"ui form"}
									      ref={(ref) => {
										      if (!ref) return;

										      let $form = window.$(ref);
										      $form.form2('setup');
										      $form.form2('submit', {
											      method: 'POST',
											      url: '/api/v1/e-commerce/submit_confirm_email',
											      beforeSent: (object) => {
												      return object;
											      },
											      loadingButton: $form.find('button.button.approve'),
											      onSuccess: (response) => {
												      this.setState({
													      response_success_message: response.message,
												      });
												      let _redirect = setTimeout(() => {
													      window.location = '/';
													      clearTimeout(_redirect);
												      }, 4000);
											      },
											      onError: (response) => {
												      $form.find('.info.message').hide();
											      }
										      });
										      $form.find('button.button.approve').trigger('submit');
									      }}
									>
										<h3 className={"ui header"}><i className="icon inbox"/>ยืนยันอีเมล</h3>
										<div className="ui info icon message">
											<i className="notched circle loading icon"/>
											<div className="content">
												<h3 className="ui header" style={{textAlign: 'left'}}>
													ระบบกำลังทำการยืนยันอีเมล โปรดรอสักครู่...
												</h3>
											</div>
										</div>
										<input type={"hidden"}
										       name={"token"}
										       value={window.Global.getAllQueryStrings().t}
										/>
										<div className="field GoogleRecaptcha_root"/>
										<div className="ui error message"
										     style={{marginBottom: 0}}
										/>
										<button type={"submit"}
										        style={{display: 'none'}}
										        className={"ui button blue approve"}>
											ยืนยันอีเมล
										</button>
									</form>
									: null
							}
						</div>
					</div>
				</div>
			</>
		);
	}
}
